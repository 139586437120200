import { useRecoilState } from "recoil";

import { cartState } from "@/@stores/stores";
import { CartItem, Product } from "@/@types";

export default function useCart() {
  const [cart, setCart] = useRecoilState(cartState);

  const handleAddToCart = (product: Product) => {
    const cartItem = cart.find(
      (item: CartItem) => item.product.id === product.id
    );
    if (cartItem) {
      const newCart = cart.filter(
        (item: CartItem) => item.product.id !== product.id
      );
      setCart(newCart);
    } else {
      const newCart = [...cart, { product, quantity: 1 }];
      setCart(newCart);
    }

    // gtag event
    (window as any).gtag("event", "add_to_cart", {
      value: product.name,
      items: [product],
    });
  };

  // handle add to cart and sum to quantity
  const handleAddToCartWithQuantity = (product: Product, quantity: number) => {
    const cartItem = cart.find(
      (item: CartItem) => item.product.id === product.id
    );
    if (cartItem) {
      const newCart = cart.map((item: CartItem) => {
        if (item.product.id === product.id) {
          return { product, quantity: item.quantity + quantity };
        }
        return item;
      });
      setCart(newCart);
    } else {
      const newCart = [...cart, { product, quantity }];
      setCart(newCart);
    }

    // gtag event
    (window as any).gtag("event", "add_to_cart", {
      value: product.name,
      items: [product],
    });
  };

  const labelAddToCart = (product: Product) => {
    if (product.related_products && product.related_products.length > 0) {
      return "Opções";
    }

    const cartItem = cart.find(
      (item: CartItem) => item.product.id === product.id
    );
    if (cartItem) {
      return "Remover";
    }
    return "Adicionar";
  };

  const handleQuantityPerProduct = (product: Product, quantity: number) => {
    const newCart = cart.map((item: CartItem) => {
      if (item.product.id === product.id) {
        return { product, quantity };
      }
      return item;
    });
    setCart(newCart);
  };

  const removeFromCart = (product: Product) => {
    const newCart = cart.filter(
      (item: CartItem) => item.product.id !== product.id
    );
    setCart(newCart);

    // gtag event
    (window as any).gtag("event", "remove_from_cart", {
      value: product.name,
      items: [product],
    });
  };

  return {
    handleAddToCart,
    labelAddToCart,
    handleAddToCartWithQuantity,
    handleQuantityPerProduct,
    removeFromCart,
  };
}
