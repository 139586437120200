import styles from "./Grid.module.scss";

interface Props {
  children: any;
  paddingBottom?: string;
  gap?: string;
}
export default function Grid(props: Props) {
  const { children, paddingBottom = "3rem", gap = "2px" } = props;
  return (
    <div
      className={styles.grid}
      style={{
        paddingBottom,
        gap,
      }}
    >
      {children}
    </div>
  );
}
