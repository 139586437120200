import { Product } from "@/@types";
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const cartState = atom({
  key: "cartState",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const wishlistState = atom({
  key: "wishlistState",
  default: [] as Product[],
  effects_UNSTABLE: [persistAtom],
});

export const userState = atom({
  key: "userState",
  default: {
    name: null,
    surname: null,
    uuid: null,
    cpf_cnpj: null,
    email: null,
    telefone: null,
    endereco: null,
  },
  effects_UNSTABLE: [persistAtom],
});

export const consentState = atom({
  key: "consentState",
  default: false,
  effects_UNSTABLE: [persistAtom],
});
