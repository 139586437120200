"use client";

import { createContext, useContext, useState } from "react";
import ProductDetail from "@/components/ProductDetail";
import { Product } from "@/@types";
import Modal from "@/components/Modal";

interface ModalContextProps {
  showModal: (product: Product) => void;
  hideModal: () => void;
  open: boolean;
}

const ModalContext = createContext<ModalContextProps>({} as ModalContextProps);

export const useModalContext = () => useContext(ModalContext);

export const ModalContextProvider = ({ children }: any) => {
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState<Product | null>(null); // [1

  const showModal = (product: Product) => {
    setProduct(product);
    setOpen(!open);

    // gtag event
    (window as any).gtag("event", "view_item", {
      value: product.name,
      items: [product],
    });
  };

  const hideModal = () => {
    setOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        showModal,
        hideModal,
        open,
      }}
    >
      <Modal>
        <ProductDetail product={product} showLinkToProduct />
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};
