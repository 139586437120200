export function defineImageUrl(path: string) {
  return `${process.env.NEXT_PUBLIC_API_URL}/${path}`;
}

// strftime function
export function strftime(date: Date, format: string) {
  const map: { [key: string]: number } = {
    d: date.getDate(),
    m: date.getMonth() + 1,
    y: date.getFullYear(),
    H: date.getHours(),
    M: date.getMinutes(),
    S: date.getSeconds(),
  };

  return format.replace(/%([dmyHMS])/g, (_, p1) => {
    const value = map[p1];

    // Note: we prepend 0 to single-digit values.
    return String(value).padStart(2, "0");
  });
}

// break the string in this formats 9x9 or 9x9x9 into an object of dimensions being the first number the width and the second the height and the third the depth
// if its 9H, its only height, if its 9D its only depth
export function breakDimensions(dimensions: string) {
  dimensions = dimensions?.toLowerCase();
  if (dimensions?.includes("x") || dimensions?.match(/\d+(h|d)/)) {
    // if its followed by an H, its only height, if its followed by a D its only depth
    const dimensionsArray = dimensions.split("x");
    let width = 0;
    let height = 0;
    let depth = 0;
    let prof = 0;

    // iterate over the array
    dimensionsArray.forEach((dimension, index) => {
      const floatDimension = parseFloat(dimension.replace(",", "."));

      const matchW = dimension.match(/\d+(l)/);
      const matchH = dimension.match(/\d+(h)/);
      const matchD = dimension.match(/\d+(d)/);
      const matchP = dimension.match(/\d+(p)/);
      // if its the first item, its the width

      if (index === 0 && !matchH && !matchD && !matchP && !matchW) {
        width = floatDimension;
      }

      if (index === 1 && !matchD && !matchP && !matchW) {
        height = floatDimension;
      }

      if (index === 2 && !matchH && !matchP && !matchW) {
        depth = floatDimension;
      }

      if (matchH) {
        height = floatDimension;
      }

      if (matchD) {
        depth = floatDimension;
      }

      if (matchP) {
        prof = floatDimension;
      }

      if (matchW) {
        width = floatDimension;
      }
    });

    const object = {
      LARGURA: Math.round(width * 100),
      ALTURA: Math.round(height * 100),
      PROFUNDIDADE: Math.round(prof * 100),
      DIÂMETRO: Math.round(depth * 100),
    };

    return Object.entries(object)
      .map(([key, value]) => {
        if (value) {
          return `${key}: ${value}cm`;
        }
      })
      .filter((value) => value)
      .join("<br>");
  } else {
    if (dimensions) {
      return `TAMANHO: ${dimensions.toUpperCase()}`;
    } else {
      return null;
    }
  }
}
