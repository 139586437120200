import { HTMLAttributes } from "react";
import styles from "./Select.module.scss";

interface Option {
  label: string;
  value: string;
}

interface Props extends HTMLAttributes<HTMLSelectElement> {
  label: string;
  options: Option[];
  onChange: (e: any) => void;
}

export default function Select(props: Props) {
  const { label, options, onChange, ...rest } = props;

  return (
    <div className={styles.Select}>
      <label className={styles.Select__label}>{label}</label>
      <select className={styles.Select__select} onChange={onChange} {...rest}>
        {options?.map((option: Option) => (
          <option
            key={`option-${option.value}`}
            value={option.value}
            className={styles.Select__option}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
