import { useEffect, useRef } from "react";
import { useModalContext } from "@/contexts/ModalContext";

import styles from "./Modal.module.scss";
import classNames from "classnames";
import { IoClose } from "react-icons/io5";

interface Props {
  children: JSX.Element;
}

export default function Modal(props: Props) {
  const { children } = props;
  const { hideModal, open } = useModalContext();

  const modalRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (open) {
      modalRef.current?.showModal();
    } else {
      setTimeout(() => {
        modalRef.current?.close();
      }, 150);
    }
  }, [open]);

  return (
    <dialog
      ref={modalRef}
      className={classNames({ [styles.modal]: true, [styles.closed]: !open })}
    >
      <div onClick={hideModal} className={styles.close}>
        <IoClose color="var(--primary)" size={20} />
      </div>
      <div
        style={{
          overflow: "hidden",
          // borderRadius: "0.5rem",
        }}
      >
        {children}
      </div>
    </dialog>
  );
}
