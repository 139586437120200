import { useRecoilState } from "recoil";

import { wishlistState } from "@/@stores/stores";
import { Product } from "@/@types";

export default function useWishlist() {
  const [wishlist, setWishlist] = useRecoilState(wishlistState);

  const handleAddWishlist = (product: Product) => {
    // add to wishlist if not exists and remove if exists
    const isInWishlist = wishlist.find(
      (item: Product) => item.id === product.id
    );
    if (isInWishlist) {
      const newWishlist = wishlist.filter(
        (item: Product) => item.id !== product.id
      );
      setWishlist(newWishlist);
    } else {
      const newWishlist = [...wishlist, product];
      setWishlist(newWishlist);
    }

    // gtag event
    (window as any).gtag("event", "add_to_wishlist", {
      value: product.name,
      items: [product],
    });
  };

  const checkIfIsInWishlist = (product: Product) => {
    return wishlist.find((item: Product) => item.id === product?.id);
  };

  return {
    handleAddWishlist,
    checkIfIsInWishlist,
  };
}
