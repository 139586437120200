import Link from "next/link";
import styles from "./Submenu.module.scss";
import { AnimatePresence } from "framer-motion";
import { Category } from "@/@types";

interface Props {
  setMenuActive: (active: boolean) => void;
  menuActive: boolean;
  categories: any;
}

export default function Submenu(props: Props) {
  const { setMenuActive, menuActive, categories } = props;

  return (
    <AnimatePresence>
      {menuActive && (
        <div className={styles.submenu}>
          <div className={styles.submenu__container}>
            {categories?.map((item: Category, index: number) => (
              <Link
                prefetch={false}
                href={`/produtos/${item.slug}`}
                key={index}
                className={styles.submenu__link}
                onClick={() => {
                  setMenuActive(false);
                }}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </AnimatePresence>
  );
}
