"use client";

import Link from "next/link";

import styles from "./Cookie.module.scss";
import { useRecoilState } from "recoil";
import { consentState } from "@/@stores/stores";
import { useEffect, useState } from "react";

export default function Cookie() {
  const [show, setShow] = useState(false);
  const [consentAccepted, setConsentAccepted] = useRecoilState(consentState);

  useEffect(() => {
    if (!consentAccepted) {
      setShow(true);
    }
  }, []);

  const acceptCookieConsent = () => {
    setConsentAccepted(true);
    setShow(false);
  };

  if (!show) return null;

  return (
    <div className={styles.cookie}>
      <p>
        <strong>Atenção! Utilizamos cookies.</strong> Ao continuar navegando,
        você concorda com a nossa{" "}
        <Link prefetch={false} href="/politica-de-privacidade">
          política de privacidade
        </Link>
        .
      </p>
      <div className={styles.cookie__buttonarea} onClick={acceptCookieConsent}>
        <span className={styles.cookie__button}>OK</span>
      </div>
    </div>
  );
}
