import classNames from "classnames";
import styles from "./Badge.module.scss";

interface Props {
  value: number;
  color: string;
  position?: "top" | "middle";
}

export default function Badge(props: Props) {
  const { value, position = "top", color } = props;

  if (value === 0) return <span />;

  return (
    <span
      key={value}
      className={classNames(styles.badge, styles[position])}
      style={{ backgroundColor: color }}
    >
      {value}
    </span>
  );
}
