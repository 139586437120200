"use client";

import Link from "next/link";

import styles from "./WhatsApp.module.scss";
import { consentState } from "@/@stores/stores";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { BiSupport } from "react-icons/bi";

export default function WhatsApp() {
  const [show, setShow] = useState(false);
  const [consentAccepted, setConsentAccepted] = useRecoilState(consentState);

  useEffect(() => {
    if (!consentAccepted) {
      setShow(true);
    }
  }, []);

  useEffect(() => {
    if (consentAccepted) {
      setShow(false);
    }
  }, [consentAccepted]);

  return (
    <Link
      prefetch={false}
      href="https://api.whatsapp.com/send?phone=5511994651299"
      target="_blank"
      className={classNames({
        [styles.whatsapp]: true,
        [styles.show]: show,
      })}
    >
      <BiSupport size={24} />
    </Link>
  );
}
