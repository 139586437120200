import { Dispatch, HTMLAttributes, SetStateAction } from "react";

import { BiMinus as Minus, BiPlus as Plus } from "react-icons/bi";

import styles from "./Quantity.module.scss";
import { Montserrat } from "next/font/google";

const montserrat = Montserrat({
  subsets: ["latin"],
  weight: ["200", "300", "400", "500", "600", "700", "800", "900"],
});
interface Props extends HTMLAttributes<HTMLDivElement> {
  quantity: number;
  setQuantity: Dispatch<SetStateAction<number>>;
  size?: "small" | "medium";
  label?: string;
}

export default function Quantity(props: Props) {
  const { size, quantity, setQuantity, label = "", ...rest } = props;

  const handlePlus = () => {
    setQuantity((prev) => prev + 1);
  };

  const handleMinus = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  return (
    <div className={styles.Quantity__wrapper}>
      {label && <span className={styles.Quantity__label}>{label}</span>}

      <div
        className={styles.Quantity}
        style={{
          fontSize: size === "small" ? "0.75rem" : "1rem",
          paddingLeft: size === "small" ? "0.25rem" : "0.5rem",
          paddingRight: size === "small" ? "0.25rem" : "0.5rem",
        }}
        {...rest}
      >
        <button
          className={styles.Quantity__minus}
          onClick={(e) => {
            handleMinus();
            e.preventDefault();
          }}
        >
          <Minus color="var(--primary)" size={16} />
        </button>
        <input
          type="number"
          className={styles.Quantity__input}
          value={quantity}
          style={{
            fontSize: size === "small" ? "0.75rem" : "0.9rem",
            paddingTop: size === "small" ? "0.5rem" : "0.75rem",
            paddingBottom: size === "small" ? "0.5rem" : "0.75rem",
            ...montserrat.style,
          }}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value > 0) {
              setQuantity(value);
            }
          }}
        />
        <button
          className={styles.Quantity__plus}
          onClick={(e) => {
            handlePlus();
            e.preventDefault();
          }}
        >
          <Plus color="var(--primary)" size={16} />
        </button>
      </div>
    </div>
  );
}
